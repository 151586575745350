<template>
  <div class="project-create">
    <div class="manage-wrapper">
      <div class="project-create-container">
        <el-tabs v-model="activeName">
          <el-tab-pane label="编辑沙龙" name="first">
            <el-form ref="projectCreateForm" :model="form" label-width="180px" :rules="rules">

              <el-form-item label="沙龙名称：" prop="name" required>
                <el-input v-model="form.name" maxlength="100" show-word-limit style="width:400px"></el-input>
              </el-form-item>

              <el-form-item label="沙龙简介：">
                <el-input type="textarea" class="textarea" :rows="6" maxlength="2500" show-word-limit
                  v-model="form.courseExplain" resize="none"></el-input>
              </el-form-item>
              <el-form-item label="是否为精品课：" prop="boutique" required>
                <el-radio-group v-model="form.boutique">
                  <el-radio :label="1">是</el-radio>
                  <el-radio :label="0">否</el-radio>
                </el-radio-group>
              </el-form-item>

              <div class="teahcers flex-align">
                <el-form-item label="授课老师：" prop="teacherId" required>
                  <teacherSelect :requestType="'teacher'" :courseId="Number($route.query.proId)"
                    :role="form.teacherRole" :selected="form.teacherId" @teacherSelect="handleTeacherChange" />
                </el-form-item>
                <el-form-item label="助教老师：" class label-width="100px">
                  <teacherSelect :requestType="'assistant'" :courseId="Number($route.query.proId)"
                    :role="form.assistantRole" :selected="form.assistantId" @teacherSelect="handleTeacherChange" />
                </el-form-item>
              </div>

              <el-form-item label="主题封面：">
                <UploadCover @uploadCover="handleCoverChange" :xcover="form.cover"></UploadCover>
              </el-form-item>

              <!-- 按钮组 -->
              <el-form-item class="el-form-item-btns" label-width="0px">
                <el-button @click="$router.push('/home/manage/salon')">取消</el-button>
                <el-button type="primary" @click="onSubmit">确定</el-button>
              </el-form-item>
              <br />
              <br />
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>
<script>
  import teacherSelect from "./components/teacherSelect.vue";
  export default {
    components: {
      teacherSelect
    },
    data() {
      var validateProjectId = (rule, value, callback) => {
        // console.log(value)
        if (value === '') {
          callback(new Error('请选择所属项目!'));
        } else {
          callback();
        }
      };
      return {
        activeName: "first",
        form: {
          name: "", //课程名称
          courseExplain: "", //课程简介
          projectId: null, //项目id
          teacherId: 0, //老师id
          assistantId: 0, //助教id
          cover: "", //主题封面
          teacherRole: 'expert', //授课老师身份
          assistantRole: 'assistant', //助教老师身份
          boutique: 0, // 是否为精品课 0否 1是
        },
        // 表单验证规则
        rules: {
          name: [{
            required: true,
            message: "请填写课程名称",
            trigger: "blur"
          }],
          teacherId: [{
            required: true,
            message: "请选择授课老师",
            trigger: "change"
          }],
          teacherName: [{
            required: true,
            message: "请填写教师名称",
            trigger: "change"
          }],
        },
      };
    },
    methods: {
      // 处理封面
      handleCoverChange(data) {
        // console.log(data)
        this.form.cover = data.md5
      },
      // 切换教师
      handleTeacherChange(data) {
        // console.log(data);
        let type = data.type === 'teacher' ? true : (data.type === 'assistant' ? false : '')
        if (type) {
          this.form.teacherId = data.teacherId
          this.form.teacherRole = data.role
        } else {
          this.form.assistantId = data.teacherId
          this.form.assistantRole = data.role
        }
        // console.log(this.form);
      },
      // 提交表单
      async commitForm() {
        // console.log(this.form);
        this.form.projectId = Number(this.$route.query.proId)
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          background: 'rgba(255, 255, 255, 0.7)'
        });
        let resData = await this.$Api.Course.editSalon(this.form);
        // console.log(resData);
        if (resData.code == 200) {
          loading.close();
          this.$notify.success('编辑成功')
          this.$router.push('/home/manage/salon')
        } else {
          loading.close();
          this.$notify.error({
            title: "错误",
            message: resData.msg
          });
        }
      },
      //创建课程
      async onSubmit() {
        this.$refs['projectCreateForm'].validate((valid) => {
          // console.log('valid',valid);
          if (valid) {
            this.commitForm();
          } else {
            this.$message.error({
              title: "错误",
              message: "请正确填写数据"
            });
          }
        })
      },
      // 获取回显信息
      async getSalonInfo() {
        let resData = await this.$Api.Course.getSalonCreateDetail(this.$route.params.id)
        console.log('回显数据', resData.data)
        if (resData.code == 200) {
          this.form = resData.data
        }
      }
    },
    created() {},
    mounted() {
      this.getSalonInfo()
    }
  };
</script>

<style lang="less" scoped>
  @import "../manage_project/less/manage-project.less";
</style>